import Jquery from './jquery-3.7.1.min.js';
window.jQuery = Jquery;
window.$ = Jquery;
import bootstrap from './bootstrap.min.bundle.js';

window.bootstrap = bootstrap;

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

window.moment = require('moment');

//import Chart from 'chart.js/auto';
const { Chart } = await import('chart.js');
import { getRelativePosition } from 'chart.js/helpers';

